import { ReactElement } from 'react';
import { IFragmentCopyWithCtaBlockFields } from 'types/contentful';
import CTA from './Cta';
import RichText from './Richtext';

export default function CopyWithCtaBlock({
  image,
  copy,
  cta,
  heading,
  backgroundColor,
}: IFragmentCopyWithCtaBlockFields): ReactElement {
  const textColor = backgroundColor === 'white' ? 'black' : backgroundColor;
  const headingColor = !backgroundColor && image ? 'white' : 'primary-actual';

  const Content = (): ReactElement => {
    return (
      <div className="px-4 lg:pl-20 py-12 w-full lg:w-2/3 leading-relaxed text-center lg:text-left">
        {heading && (
          <h2 className={`font-heading font-extrabold text-2xl lg:text-4xl text-${headingColor}`}>
            {heading}
          </h2>
        )}
        {copy !== undefined && <RichText document={copy} classNames="text-sm lg:text-lg mt-4" />}
        {cta !== undefined && <CTA {...cta.fields} backgroundColor={'white'} classNames="mt-6" />}
      </div>
    );
  };

  const WithImage = (): ReactElement => {
    return (
      <div
        style={{
          backgroundImage: `urL(${image?.fields.file.url}?w=715&h=425)`,
          backgroundSize: 'cover',
        }}
        className="text-white h-full w-full relative inset-0"
      >
        <div className="h-full w-full relative bg-black bg-opacity-50">
          <Content />
        </div>
      </div>
    );
  };

  const WithColor = (): ReactElement => {
    const borderColor = backgroundColor === 'white' ? 'muted' : backgroundColor;
    return (
      <div
        className={`bg-${backgroundColor} text-${textColor} h-full w-full relative inset-0 border-t border-${borderColor} lg:border-0`}
      >
        <Content />
      </div>
    );
  };

  return backgroundColor ? <WithColor /> : <WithImage />;
}
