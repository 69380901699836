import ImageAdBlock from 'components/UI/ImageLinkBlock';
import CopyWithCtaBlock from 'components/UI/CopyWithCtaBlock';
import { ReactElement } from 'react';
import {
  IComponentSideBySideFields,
  IFragmentCopyWithCtaBlock,
  IFragmentImageLinkBlock,
} from 'types/contentful';

export default function SideBySideBlock({
  leftBlock,
  rightBlock,
}: IComponentSideBySideFields): ReactElement {
  const component = (block: IFragmentCopyWithCtaBlock | IFragmentImageLinkBlock): ReactElement => {
    return block.sys.contentType.sys.id === 'fragmentCopyWithCtaBlock' ? (
      <CopyWithCtaBlock {...(block as IFragmentCopyWithCtaBlock).fields} />
    ) : (
      <ImageAdBlock {...(block as IFragmentImageLinkBlock).fields} imageQuery="w=715&h=425" />
    );
  };

  return (
    <div className="flex flex-col lg:flex-row lg:py-5">
      <div className="w-full lg:w-1/2 border-transparent lg:border-r-4">{component(leftBlock)}</div>
      <div className="w-full lg:w-1/2 border-transparent lg:border-l-4">
        {component(rightBlock)}
      </div>
    </div>
  );
}
