import React, { ReactElement } from 'react';
import { IFragmentImageLinkBlockFields } from 'types/contentful';

import Image from './Image';
import Link from './Link';

interface ImageLinkBlockProps extends IFragmentImageLinkBlockFields {
  imageQuery?: string;
  classNames?: string;
}

const DEFAULT_IMAGE_CLASSES = 'h-full object-cover';

export default function ImageLinkBlock({
  image,
  link,
  linkLabel,
  openInNewTab,
  imageQuery = '',
  classNames = '',
}: ImageLinkBlockProps): ReactElement {
  const isExternal = link.startsWith('http');
  const target = openInNewTab !== undefined && openInNewTab === true ? '_blank' : '';

  const Internal = (): ReactElement => (
    <Link slug={link}>
      <a target={target} className="w-full" aria-label={linkLabel}>
        <Image
          image={image}
          query={imageQuery}
          classNames={classNames ? classNames : DEFAULT_IMAGE_CLASSES}
        />
      </a>
    </Link>
  );

  const External = (): ReactElement => (
    <a href={link} target={target} className="w-full" rel="noreferrer" aria-label={linkLabel}>
      <Image
        image={image}
        query={imageQuery}
        classNames={classNames ? classNames : DEFAULT_IMAGE_CLASSES}
      />
    </a>
  );

  return isExternal ? <External /> : <Internal />;
}
